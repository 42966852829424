// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/agreement/components/Agreements.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/agreement/components/Agreements.tsx");
  import.meta.hot.lastModified = "1734390566000";
}
// REMIX HMR END

import React from 'react';
import { AgreementFetcher } from '../fetchers/agreement-fetcher';
import { Error } from '~common/components/Error';
import { Agreement } from '~common/components/cart/Agreement';
import { Loading } from '~common/components/loading/Loading';
export const Agreements = ({
  ids
}) => {
  return <AgreementFetcher ids={ids}>
      {({
      items,
      loading,
      exception,
      onReload
    }) => {
      if (loading) {
        return <Loading />;
      }
      if (items) {
        return items.map(a => <Agreement key={a.id} agreement={a} />);
      }
      return <Error error={exception} onReload={onReload} />;
    }}
    </AgreementFetcher>;
};
_c = Agreements;
var _c;
$RefreshReg$(_c, "Agreements");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;