import clsx from 'clsx';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ValidatedForm, useIsSubmitting } from 'remix-validated-form'
import { Button } from '~ui/Button';
import { LoadingIndicator } from '~ui/LoadingIndicator';
import { validator } from './validator';


const RemoveCouponForm: React.FC<{
  couponCode: string
  currentCouponCodes: string[]
  removePath: string
  className?: string
  // loading?: boolean
}> = ({
  couponCode,
  currentCouponCodes = [],
  removePath,
  className,
}) => {
  const id = `remove-coupon-form-${couponCode}`
  const isSubmitting = useIsSubmitting(id)

  return (
    <ValidatedForm
      id={id}
      method="POST"
      validator={validator}
      action={removePath}
      resetAfterSubmit
    >
      <div
        className={clsx(
          'flex items-center gap-2 text-base font-medium text-gray-700',
          className,
        )}
      >
        <input type="hidden" name="action" value="removeCoupon" />
        <input type="hidden" name="intent" value="removeCouponCode" />
        <input type="hidden" name="couponCode" value={couponCode} />
        <input
          type="hidden"
          name="currentCouponCodes"
          value={currentCouponCodes.join(',')}
        />
        <p className="font-bold dark:text-slate-300">{couponCode}</p>
        <Button
          type="submit"
          disabled={isSubmitting}
          variant="secondary"
          className="focus:ring-transparent"
          // onClick={handleRemoveCoupon}
        >
          {isSubmitting ? (
            <LoadingIndicator className="w-4 h-4" />
          ) : (
            <TrashIcon className="h-4 w-4 text-gray-600 hover:text-gray-900 dark:text-slate-400 dark:hover:text-slate-300" />
          )}
        </Button>
      </div>
    </ValidatedForm>
  )
}

export { RemoveCouponForm };
