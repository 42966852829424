import { Agreement as AgreementType } from "~common/generated/admin-graphql"
import LexicalEditor from '~common/components/lexical/LexicalEditor'

export const Agreement: React.FC<{ agreement: AgreementType }> = ({ agreement }) => {
  return (
    <div key={agreement.id} className="w-full mb-4">
      <h2 className="dark:text-slate-300 text-md font-semibold mb-2">
        {agreement.label}
      </h2>
      {agreement.agreement ? (
        <div className="max-h-72 overflow-y-auto">
          <LexicalEditor
            editorContent={agreement.agreement}
            onChange={() => {}}
            isReadOnly={true}
          />
        </div>
      ) : null}
    </div>
  )
}