import React from 'react'
import { OrderDetailFragment } from '~common/generated/storefront-graphql'
import { Price } from '../product'

export const useDiscountMap = (
  order?: OrderDetailFragment,
): {
  [couponCode: string]: {
    description: string
    payNow: number
    recurring: number
  }
} => {
  const discountMap = React.useMemo<{
    [couponCode: string]: {
      description: string
      payNow: number
      recurring: number
    }
  }>(() => {
    if (!order) {
      return {}
    }
    const promotions = order.promotions?.reduce<{ [key: string]: string }>(
      (acc, p) => {
        acc[p.name] = p.couponCode ?? ''
        return acc
      },
      {},
    )
    const discountMap: {
      [couponCode: string]: {
        description: string
        payNow: number
        recurring: number
      }
    } = {}
    for (const discount of order.discounts) {
      // this assumes promotion.name === discount.description
      discountMap[promotions[discount.description]] = {
        description: discount.description,
        payNow: discount.amount,
        recurring: 0,
      }
    }

    for (const line of order.lines) {
      const discounts = line.subscriptionDetails?.discountMap ?? {}
      for (const couponCode of Object.keys(discounts)) {
        if (!discountMap[couponCode]) {
          discountMap[couponCode] = {
            description:
              order.promotions.find((p) => p.couponCode === couponCode)?.name ??
              couponCode,
            payNow: 0,
            recurring: 0,
          }
        }
        discountMap[couponCode].recurring =
          discountMap[couponCode].recurring - (discounts[couponCode] ?? 0)
      }
      for (const couponCode of order.couponCodes) {
        if (!discountMap[couponCode]) {
          discountMap[couponCode] = {
            description:
              order.promotions.find((p) => p.couponCode === couponCode)?.name ??
              couponCode,
            payNow: 0,
            recurring: 0,
          }
        }
      }
    }
    return discountMap
  }, [order])

  return discountMap
}

export const DiscountMap: React.FC<{
  order?: OrderDetailFragment
  renderRemove: (couponCode: string) => React.ReactNode
  className?: string
}> = ({ order, renderRemove, className }) => {
  const discountMap = useDiscountMap(order)
  return (
    <>
      {Object.keys(discountMap).length > 0 ? (
        <div className={className}>
          {Object.keys(discountMap).map((couponCode, idx) => (
            <div
              key={`discount-${couponCode}`}
              className="pb-2 text-sm font-medium text-gray-900 dark:text-slate-400"
            >
              <p>
                {discountMap[couponCode].description ?? couponCode} Promotion
              </p>

              {order?.currencyCode && (
                <div className="flex justify-between items-start flex-wrap">
                  {renderRemove(couponCode)}
                  <div className="text-sm font-regular w-full grid dark:text-slate-300">
                    {discountMap[couponCode].recurring < 0 ? (
                      <div className="w-full grid grid-cols-6">
                        <p className="col-start-3 col-end-5 lg:col-start-5 lg:col-end-7 text-slate-400">
                          Recurring
                        </p>
                        <div className="col-start-6 lg:col-start-9 text-right">
                          <Price
                            priceWithTax={discountMap[couponCode].recurring}
                            currencyCode={order.currencyCode}
                          />
                        </div>
                      </div>
                    ) : null}
                    {discountMap[couponCode].payNow < 0 ? (
                      <div className="w-full grid grid-cols-6">
                        <p className="col-start-3 col-end-5 lg:col-start-5 lg:col-end-7 text-slate-400">
                          Pay now
                        </p>
                        <div className="col-start-6 lg:col-start-9 text-right">
                          <Price
                            priceWithTax={discountMap[couponCode].payNow ?? 0}
                            currencyCode={order.currencyCode}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}
