import React from 'react'
import { Price } from '~common/components/product/Price'
import { PaymentScheduleDetails } from '~common/components/cart/PaymentScheduleDetails'
import { Button } from '~ui/Button'
import { OrderLine, CurrencyCode } from '~common/generated/storefront-graphql'
import clsx from 'clsx'
import {
  CalendarIcon,
  CurrencyDollarIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline'
import { userDateFormat } from '~common/utils/dateHelper'
import { Subscription, SubscriptionInterval } from '../product'
import { DisplayType, EditType } from './types'

export const SubscriptionRow: React.FC<
  React.PropsWithChildren<{
    subscription: Subscription
    line: OrderLine
    priceIncludingTax?: boolean
    currencyCode?: CurrencyCode
    type: 'recurring' | 'registration'
    displayEdit: boolean
    displayDetails?: boolean
    displaySurcharge?: boolean
    defaultToOpen?: boolean
    editable?: EditType[]
    onSubscriptionEdit?: (
      type: 'recurring' | 'registration',
      editType: EditType,
      displayType: DisplayType,
    ) => void
  }>
> = ({
  subscription,
  line,
  priceIncludingTax,
  currencyCode = CurrencyCode.Usd,
  type,
  displayEdit,
  displaySurcharge,
  displayDetails = true,
  defaultToOpen = true,
  editable = [],
  onSubscriptionEdit,
  children,
}) => {
  const isEdit = React.useMemo(() => {
    return !!(
      onSubscriptionEdit &&
      editable.find((s) => ['amounts', 'interval', 'dates'].includes(s))
    )
  }, [displayEdit, onSubscriptionEdit, editable])
  const {
    unitPriceWithTax,
    productVariant: { name, price, priceWithTax, product },
    subscriptionDetails,
  } = line
  const { startDate: variantStartDate, autoRenew } =
    line.productVariant.customFields ?? {}
  const {
    programPrice,
    downpayment = 0,
    amount,
    recurringSubtotal = 0,
    amountDueNow,
    discountPerInterval,
    downpaymentIsRegistration,
    discountedDownpayment = 0,
    recurringAmountIncPromotions = 0,
    downpaymentWithSurcharge = 0,
    onetimeDiscount,
    proration,
    recurringDiscount = 0,
    recurringRegistrationDiscount = 0,
    recurringPromotionDiscounts = 0,
    recurringAmountSurcharge = 0,
    downpaymentSurcharge = 0,
  } = subscriptionDetails ?? {
    programPrice: 0,
    amount: 0,
  }
  const { pricingInterval } = product.customFields ?? {}
  const {
    recurring: { /*amount,*/ interval, intervalCount, startDate, endDate },
  } = subscription ?? {}
  const {
    downpayment: lineDownpayment,

    // downpaymentIsRegistration,
    // onetimeDiscount,
    // recurringDiscount,
    // recurringRegistrationDiscount,
  } = line.customFields ?? {}

  const unitPrice = priceIncludingTax ? unitPriceWithTax : line.unitPrice

  const linePrice = priceIncludingTax ? priceWithTax : price
  let recurringPrice = unitPrice

  // if (line.customFields?.downpayment) {
  //   totalProratedAmount =
  //     subscription.amountDueNow > line.customFields?.downpayment
  //       ? subscription.amountDueNow - line.customFields?.downpayment
  //       : subscription.amountDueNow

  //   downpayment = line.customFields.downpayment
  // } else {
  //   totalProratedAmount = subscription.amountDueNow
  // }
  // The subscription is what will be sent to the processor
  // the line customFields is how we break that down. They should match
  // But in case of divergence, the subscription is what counts

  if (typeof amount !== 'undefined') {
    recurringPrice = amount
  }

  let finalAmount =
    (type === 'recurring' ? recurringAmountIncPromotions : downpayment) ?? 0

  let recurringAmount =
    (type === 'recurring'
      ? recurringAmountIncPromotions -
        recurringDiscount -
        recurringPromotionDiscounts
      : lineDownpayment) ?? 0

  if (displaySurcharge) {
    finalAmount =
      (type === 'recurring' ? amount : downpaymentWithSurcharge) ?? 0

    recurringAmount = linePrice
  }

  return (
    <div className={clsx({ 'mt-0.5': type === 'registration' })}>
      {
        unitPrice ? (
          <PaymentScheduleDetails
            programPrice={programPrice}
            duration={intervalCount!}
            productInterval={pricingInterval ?? SubscriptionInterval.month}
            interval={interval as string}
            intervalCount={intervalCount}
            type={type}
            downpayment={downpayment}
            downpaymentIsRegistration={downpaymentIsRegistration ?? undefined}
            recurringSubtotal={recurringSubtotal}
            amount={recurringAmount}
            finalAmount={finalAmount}
            discountedDownpayment={discountedDownpayment}
            downpaymentSurcharge={downpaymentSurcharge}
            recurringRegistrationDiscount={recurringRegistrationDiscount}
            recurringDiscount={recurringDiscount}
            recurringPromotionDiscount={recurringPromotionDiscounts}
            recurringAmountSurcharge={recurringAmountSurcharge}
            discount={
              type === 'recurring'
                ? (recurringDiscount ?? 0)
                : (recurringRegistrationDiscount ?? 0)
            }
            discountPerInterval={discountPerInterval}
            currency={currencyCode}
            startDate={startDate}
            proration={Number(type === 'recurring' ? proration : 0)}
            displayDetails={displayDetails}
            displaySurcharge={displaySurcharge}
            defaultToOpen={defaultToOpen}
            editable={editable}
            autoRenew={!!autoRenew}
            onEdit={
              onSubscriptionEdit
                ? (editType?: EditType, displayType?: DisplayType) => {
                    onSubscriptionEdit(
                      type === 'registration' ? 'registration' : 'recurring',
                      editType ?? 'amounts',
                      displayType ?? 'amounts',
                    )
                  }
                : undefined
            }
          />
        ) : null
        // <div className="flex justify-between text-xs font-medium text-gray-500">
        //   <p>
        //     <Price
        //       priceWithTax={recurringPrice}
        //       // interval={interval!}
        //       // intervalCount={intervalCount!}
        //       currencyCode={currencyCode}
        //     />
        //   </p>
        // </div>
      }

      {type === 'registration' ? (
        <div className="text-xs flex justify-between font-medium text-gray-500 dark:text-slate-300 mt-0.25">
          <p className="font-bold">
            <span className="dark:text-slate-400">Pay now: </span>
            {downpaymentIsRegistration
              ? 'registration fee'
              : 'downpayment'}{' '}
            {/*discountedDownpayment === 0 ? (
              <span>
                <Price
                  priceWithTax={downpayment}
                  currencyCode={currencyCode}
                  className={clsx({
                    'text-primary-500 cursor-pointer':
                      !!onSubscriptionEdit && editable.includes('amounts'),
                  })}bscription pay
                />
              </span>
            ) : null*/}
          </p>
          <p>
            <Price
              priceWithTax={discountedDownpayment}
              currencyCode={currencyCode}
              className="text-sm font-bold"
            />
          </p>
        </div>
      ) : (
        <></>
      )}
      {type === 'recurring' && Number(proration) > 0.0 ? (
        <div
          className={clsx(
            'text-xs flex justify-between font-medium mt-0.5 text-gray-500 dark:text-slate-300',
          )}
        >
          <p className="font-bold">
            <span className="dark:text-slate-400">
              {/* <span className="hidden sm:inline dark:text-slate-300">
                Pay now:{' '}
              </span> */}
              Pro-ration thru
            </span>
            &nbsp;
            <span
              className={clsx({
                'text-primary-500 dark:text-primary-400 cursor-pointer':
                  editable && onSubscriptionEdit,
              })}
              onClick={() => {
                onSubscriptionEdit
                  ? onSubscriptionEdit('recurring', 'dates', 'charge-date')
                  : undefined
              }}
            >
              {userDateFormat(startDate)}
            </span>
          </p>
          <p>
            <Price
              priceWithTax={proration}
              currencyCode={currencyCode}
              className="text-sm font-bold"
            />
          </p>
        </div>
      ) : (
        <></>
      )}
      {children}
      {displayEdit && isEdit ? (
        <div className="flex items-center gap-2 text-sm ml-1 py-0.5 mt-0.5 border-b-1 border-primary-900 dark:border-slate-500">
          <span className="hidden sm:block font-medium dark:text-slate-400">
            Edit
          </span>
          {editable?.includes('amounts') ? (
            <Button
              variant="unstyled"
              className="gap-0.5 font-base text-primary-500 hover:text-primary-600 dark:text-slate-400 dark:hover:text-slate-300"
              iconStart={
                <CurrencyDollarIcon className="h-4 w-4" aria-hidden="true" />
              }
              onClick={() => {
                // onSubscriptionEdit('pro-ration')
                onSubscriptionEdit!('recurring', 'amounts', 'amounts')
              }}
            >
              Amounts
            </Button>
          ) : null}
          {editable?.includes('interval') ? (
            <Button
              variant="unstyled"
              className="gap-0.5 font-base text-primary-500 hover:text-primary-600 dark:text-slate-400 dark:hover:text-slate-300"
              iconStart={
                <CalendarIcon className="h-4 w-4" aria-hidden="true" />
              }
              onClick={() => {
                // onSubscriptionEdit('pro-ration')
                onSubscriptionEdit!('recurring', 'interval', 'interval')
              }}
            >
              Frequency
            </Button>
          ) : null}
          {editable?.includes('dates') ? (
            <Button
              variant="unstyled"
              className="gap-0.5 font-base text-primary-500 hover:text-primary-600 dark:text-slate-400 dark:hover:text-slate-300"
              iconStart={
                <CalendarDaysIcon className="h-4 w-4" aria-hidden="true" />
              }
              onClick={() => {
                // onSubscriptionEdit('pro-ration')
                onSubscriptionEdit!('recurring', 'dates', 'charge-date')
              }}
            >
              <span className="hidden sm:block">Payment dates</span>
              <span className="block sm:hidden">$ Dates</span>
            </Button>
          ) : null}
        </div>
      ) : null}
      {/* {Number(downpayment) > 0.0 || Number(totalProratedAmount) > 0.0 ? (
              <div className="flex justify-end items-center text-xs mt-1 font-medium text-gray-500">
                <p className="justify-center pr-1">Pay now</p>
                <p className="border-t-2">
                  <Price
                    priceWithTax={totalProratedAmount}
                    currencyCode={currencyCode}
                  ></Price>
                </p>
              </div>
            ) : (
              <></>
            )} */}
    </div>
  )
}
