// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/agreement/fetchers/agreement-fetcher.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/agreement/fetchers/agreement-fetcher.tsx");
  import.meta.hot.lastModified = "1734390566000";
}
// REMIX HMR END

import React from 'react';
import { useFetcher, useRevalidator } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { list } from '~storefront/providers/agreement/provider';
import { config as agreementConfig } from '~storefront/modules/agreement/config';
export async function loader({
  request
}) {
  const url = new URL(request.url);
  const ids = url.searchParams.get('ids');
  let error;
  let data;
  if (!ids) {
    error = 'Missing id';
  } else {
    try {
      data = await list({
        filter: {
          id: {
            in: ids.split(',')
          }
        }
      }, {
        request
      });
    } catch (err) {
      error = err.message;
    }
  }
  return json({
    ids,
    data: data?.agreements || null,
    error: error?.message || error
  });
}
export const AgreementFetcher = ({
  ids,
  initialItems,
  children
}) => {
  _s();
  const fetcher = useFetcher({
    key: 'agreements'
  });
  const revalidator = useRevalidator();
  const [exception, setException] = React.useState();
  const [items, setItems] = React.useState(initialItems);
  const {
    state
  } = fetcher;
  const {
    data,
    error
  } = fetcher.data || {};
  const doFetch = React.useCallback(() => {
    if (items || state !== 'idle') {
      return;
    }
    fetcher.submit({
      ids: ids.join(',')
    }, {
      action: `${agreementConfig.apiPath}`,
      method: 'GET'
    });
  }, [ids, items, state]);
  React.useEffect(() => {
    if (data && !error) {
      setItems(data.items);
    }
  }, [data, error]);
  React.useEffect(() => {
    setException(error);
  }, [error]);
  React.useEffect(() => {
    doFetch();
  }, [ids]);
  const handleReload = React.useCallback(() => {
    doFetch();
  }, []);
  React.useEffect(() => {
    if (data) {
      setItems(data.items);
    }
  }, [data]);
  React.useEffect(() => {
    if (revalidator.state === 'loading') {
      handleReload();
    }
  }, [revalidator.state]);
  return children({
    items,
    exception,
    loading: state !== 'idle',
    onReload: handleReload
  });
};
_s(AgreementFetcher, "ryo2W+jo1moCL6M409ryXzI5pd4=", false, function () {
  return [useFetcher, useRevalidator];
});
_c = AgreementFetcher;
var _c;
$RefreshReg$(_c, "AgreementFetcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;