import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { CurrencyCode } from '~common/generated/storefront-graphql'
import { Price } from '../product/Price'
import { userDateFormat } from '~common/utils/dateHelper'
import clsx from 'clsx'
import { DisplayType, EditType } from './types'

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ''
const shorten = (interval: string, intervalCount: number) => {
  switch (interval) {
    case 'Month':
    case 'month':
      if (intervalCount > 1) {
        return `${intervalCount} mths`
      }
      return 'mth'
    case 'Week':
    case 'week':
      if (intervalCount > 1) {
        return `${intervalCount} wks`
      }
      return 'wk'
    case 'Year':
    case 'year':
      if (intervalCount > 1) {
        return `${intervalCount} yrs`
      }
      return 'yr'
    default:
      return interval
  }
}
export function PaymentScheduleDetails({
  programPrice,
  duration,
  productInterval,
  interval,
  intervalCount,
  downpayment = 0,
  discountedDownpayment = 0,
  downpaymentSurcharge = 0,
  amount,
  finalAmount,
  recurringRegistrationDiscount = 0,
  recurringDiscount,
  recurringSubtotal,
  recurringPromotionDiscount,
  recurringAmountSurcharge,
  discountPerInterval = 0,
  currency,
  startDate,
  proration = 0,
  autoRenew = true,
  displayDetails = true,
  displaySurcharge,
  defaultToOpen = true,
  editable = [],
  type,
  downpaymentIsRegistration,
  onEdit,
}: {
  programPrice: number
  duration: number
  productInterval: string
  interval: string
  intervalCount: number
  downpayment: number
  discountedDownpayment: number
  downpaymentSurcharge: number
  amount: number
  finalAmount: number
  recurringRegistrationDiscount: number
  recurringSubtotal: number
  recurringDiscount: number
  recurringPromotionDiscount: number
  recurringAmountSurcharge: number
  discountPerInterval?: number
  currency: string
  startDate: string
  proration?: number
  displayDetails?: boolean
  displaySurcharge?: boolean
  defaultToOpen?: boolean
  autoRenew?: boolean
  editable?: EditType[]
  type: 'recurring' | 'registration'
  downpaymentIsRegistration?: boolean
  onEdit?: (type?: EditType, displayType?: DisplayType) => void
}) {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultToOpen)
  const handleEdit = React.useCallback(
    (e: React.MouseEvent<any>, type?: EditType, displayType?: DisplayType) => {
      if (onEdit) {
        e.preventDefault()
        e.stopPropagation()
        onEdit(type, displayType)
        if (displayDetails) {
          setIsOpen(true)
        }
      }
    },
    [onEdit],
  )
  const handleToggle = React.useCallback(() => {
    if (onEdit) {
      onEdit()
    }
    if (displayDetails) {
      setIsOpen((open) => !open)
    }
  }, [displayDetails, onEdit])

  if (duration == undefined) {
    return null
  }

  return (
    <div
      className={clsx(
        'text-xs w-full font-medium text-gray-500 dark:text-slate-300',
      )}
    >
      {type === 'registration' ? (
        <>
          <div
            className={clsx(
              'w-full grid grid-cols-12 dark:text-slate-400 overflow-y-hidden',
              {
                'max-h-0': !isOpen,
                'max-h-96': isOpen,
              },
            )}
          >
            <p className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400">
              Program price &nbsp;
              {/* <span className="">
                <Price
                  priceWithTax={programPrice}
                  currencyCode={currency as CurrencyCode}
                  className="dark:text-slate-300"
                />
              </span> */}
            </p>
            <span className="text-right col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12">
              <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                <Price
                  priceWithTax={programPrice}
                  currencyCode={currency as CurrencyCode}
                  className="dark:text-slate-300"
                />
              </span>
            </span>
          </div>
          <div
            className={clsx(
              'w-full grid grid-cols-12 transition-max-height duration-300 ease-in-out overflow-y-hidden mt-0.5',
              {
                // 'w-full justify-between': proration === 0,
                'max-h-0': !isOpen,
                'max-h-96': isOpen,
              },
            )}
            data-open={isOpen}
          >
            <span
              className={clsx('col-span-6 lg:col-span-5 xl:col-span-4', {
                'text-gray-500 dark:text-slate-400': !downpaymentIsRegistration,
                'text-success-400':
                  downpaymentIsRegistration &&
                  (!onEdit || !editable.includes('amounts')),
                'text-primary-500 dark:text-primary-400 cursor-pointer':
                  downpaymentIsRegistration &&
                  !!onEdit &&
                  editable.includes('amounts'),
              })}
            >
              {downpaymentIsRegistration ? 'Registration fee' : 'Downpayment'}
            </span>
            <span
              className={clsx(
                'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3',
                'grid grid-cols-12',
                'text-right',
              )}
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                handleEdit(e, 'amounts', 'onetime-discount')
              }}
            >
              <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                <Price
                  priceWithTax={amount}
                  currencyCode={currency as CurrencyCode}
                  className={clsx('text-right', {
                    'dark:text-slate-200': !(
                      !!onEdit && editable.includes('amounts')
                    ),
                    'text-primary-500 dark:text-primary-400 cursor-pointer':
                      !!onEdit && editable.includes('amounts'),
                  })}
                />
              </span>
            </span>
            {/*discount > 0 ? (
              <span>
                (
                <Price
                  priceWithTax={amount}
                  currencyCode={currency as CurrencyCode}
                />
                )
              </span>
            ) : null*/}
          </div>
          {!downpaymentIsRegistration ? (
            <>
              <div
                data-open={isOpen}
                className={clsx(
                  'transition-max-height duration-300 ease-in-out overflow-y-hidden',
                  {
                    'max-h-0': !isOpen,
                    'max-h-96': isOpen,
                  },
                )}
              >
                {recurringRegistrationDiscount > 0 ? (
                  <div className="w-full grid grid-cols-12">
                    <span
                      className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400"
                      title="Recurring discount"
                    >
                      Discount
                    </span>
                    <span
                      className={clsx(
                        'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3',
                        'grid grid-cols-12',
                        'text-right',
                      )}
                      onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        handleEdit(e, 'amounts', 'registration-discount')
                      }}
                    >
                      <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                        <Price
                          priceWithTax={-recurringRegistrationDiscount}
                          currencyCode={currency as CurrencyCode}
                          className={clsx('text-right', {
                            'dark:text-slate-200': !(
                              !!onEdit && editable.includes('amounts')
                            ),
                            'text-primary-500 dark:text-primary-400 cursor-pointer':
                              !!onEdit && editable.includes('amounts'),
                          })}
                        />
                      </span>
                    </span>
                  </div>
                ) : null}
                {displaySurcharge && downpaymentSurcharge > 0 ? (
                  <div className="w-full grid grid-cols-12">
                    <span
                      className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400"
                      title="Recurring discount"
                    >
                      Convenience fee
                    </span>
                    <span
                      className={clsx(
                        'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12',
                        'text-right whitespace-nowrap',
                      )}
                    >
                      <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                        <Price
                          priceWithTax={downpaymentSurcharge}
                          currencyCode={currency as CurrencyCode}
                          className={clsx('text-right dark:text-slate-200')}
                        />
                      </span>
                      {autoRenew ? (
                        <span
                          className={clsx(
                            'col-span-4 lg:col-span-5 xl:col-span-3',
                            'text-left text-success-600 dark:text-success-400',
                          )}
                        >
                          /{shorten(interval, duration)}
                        </span>
                      ) : null}
                    </span>
                  </div>
                ) : null}
                {autoRenew && finalAmount > 0 ? (
                  <span className="flex">
                    <span className="dark:text-slate-400">Renewal</span>
                    &nbsp;
                    <span
                      className={clsx({
                        'text-primary-500 dark:text-primary-400 cursor-pointer':
                          !!onEdit && editable.includes('dates'),
                      })}
                      onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        handleEdit(e, 'dates', 'charge-date')
                      }}
                    >
                      {userDateFormat(startDate)}
                    </span>
                  </span>
                ) : null}
                {autoRenew ? (
                  <div className="flex">
                    <span className="dark:text-slate-400">Every</span>&nbsp;
                    <span
                      className={clsx({
                        'text-primary-500 dark:text-primary-400 cursor-pointer':
                          !!onEdit && editable.includes('interval'),
                      })}
                      onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        handleEdit(e, 'interval')
                      }}
                    >
                      {duration > 1 ? `${duration} ` : ''}
                      {capitalize(interval)}
                      {duration > 1 ? 's' : ''}
                    </span>
                  </div>
                ) : null}
              </div>
              <div
                className={clsx('w-full grid grid-cols-12', {
                  // 'cursor-pointer': !displayDetails
                  'cursor-pointer': displayDetails,
                  // 'w-full justify-between': proration === 0,
                })}
                onClick={handleToggle}
              >
                <div className="col-span-6 lg:col-span-5 xl:col-span-5 pt-0.5 flex items-center gap-0.5 mr-0.5">
                  <span className="text-success-400">Downpayment</span>
                  <ChevronRightIcon
                    aria-hidden="true"
                    className={clsx(
                      'h-3 w-3 text-success-500 dark:text-success-400',
                      {
                        hidden: !displayDetails,
                        '-rotate-90': isOpen,
                      },
                    )}
                  />
                </div>
                <span
                  className={clsx(
                    'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12',
                    'text-right pt-0.5',
                    {
                      'border-t border-gray-600': isOpen,
                    },
                  )}
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    handleEdit(e, 'amounts', 'onetime-discount')
                  }}
                >
                  <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                    <Price
                      priceWithTax={finalAmount}
                      currencyCode={currency as CurrencyCode}
                      className={clsx('text-right', {
                        'dark:text-slate-200': !(
                          !!onEdit && editable.includes('amounts')
                        ),
                        'text-primary-500 dark:text-primary-400 cursor-pointer':
                          !!onEdit && editable.includes('amounts'),
                      })}
                    />
                  </span>
                </span>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={clsx(
              'transition-max-height duration-300 ease-in-out overflow-y-hidden',
              {
                'max-h-0': !isOpen,
                'max-h-96': isOpen,
              },
            )}
          >
            {/* <div
              className={clsx('w-full grid grid-cols-12', {
                // 'w-full justify-between': proration === 0,
              })}
            >
              <span
                className={clsx(
                  'col-span-6 lg:col-span-5 xl:col-span-4',
                  'dark:text-slate-400',
                )}
              >
                Sub total
              </span>
              <span
                className={clsx(
                  'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3',
                  'grid grid-cols-12',
                  'text-right whitespace-nowrap',
                )}
              >
                <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                  <Price
                    priceWithTax={
                      programPrice -
                      discountedDownpayment// + downpaymentSurcharge
                    }
                    currencyCode={currency as CurrencyCode}
                  />
                </span>
              </span>
            </div> */}
            <div
              className={clsx('w-full grid grid-cols-12', {
                // 'w-full justify-between': proration === 0,
              })}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                handleEdit(e, 'amounts', 'recurring-discount')
              }}
            >
              <span className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400">
                Subscription
              </span>
              <span className="col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12">
                <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                  <Price
                    priceWithTax={recurringSubtotal} //{finalAmount + recurringDiscount}
                    currencyCode={currency as CurrencyCode}
                    className={clsx('text-right', {
                      'dark:text-slate-200': !(
                        !!onEdit && editable.includes('interval')
                      ),
                      'cursor-pointer':
                        !!onEdit && editable.includes('interval'),
                    })}
                  />
                </span>
                {autoRenew ? (
                  <span
                    className={clsx(
                      'col-span-4 lg:col-span-5 xl:col-span-3',
                      'text-success-600 dark:text-success-400',
                    )}
                  >
                    /{shorten(interval, intervalCount)}
                  </span>
                ) : null}
              </span>
            </div>
            {recurringDiscount > 0 ? (
              <div className="w-full grid grid-cols-12">
                <span
                  className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400"
                  title="Recurring discount"
                >
                  Discount
                </span>
                <span
                  className={clsx(
                    'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12',
                    'text-right whitespace-nowrap',
                  )}
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    handleEdit(e, 'amounts', 'recurring-discount')
                  }}
                >
                  <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                    {/* Be aware that this is the recurring discount per selected interval, not per duration interval */}
                    <Price
                      priceWithTax={-recurringDiscount}
                      currencyCode={currency as CurrencyCode}
                      className={clsx('text-right', {
                        'dark:text-slate-200': !(
                          !!onEdit && editable.includes('amounts')
                        ),
                        'text-primary-500 dark:text-primary-400 cursor-pointer':
                          !!onEdit && editable.includes('amounts'),
                      })}
                    />
                  </span>
                  {autoRenew ? (
                    <span
                      className={clsx(
                        'col-span-4 lg:col-span-5 xl:col-span-3',
                        'text-left text-success-600 dark:text-success-400',
                      )}
                    >
                      /{shorten(interval, intervalCount)}
                    </span>
                  ) : null}
                </span>
              </div>
            ) : null}
            {recurringPromotionDiscount > 0 ? (
              <div className="w-full grid grid-cols-12">
                <span
                  className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400"
                  title="Recurring discount"
                >
                  Promotional discount
                </span>
                <span
                  className={clsx(
                    'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12',
                    'text-right whitespace-nowrap',
                  )}
                >
                  <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                    {/* Be aware that this is the recurring discount per selected interval, not per duration interval */}
                    <Price
                      priceWithTax={-recurringPromotionDiscount}
                      currencyCode={currency as CurrencyCode}
                      className={clsx('text-right dark:text-slate-200')}
                    />
                  </span>
                  {autoRenew ? (
                    <span
                      className={clsx(
                        'col-span-4 lg:col-span-5 xl:col-span-3',
                        'text-left text-success-600 dark:text-success-400',
                      )}
                    >
                      /{shorten(interval, intervalCount)}
                    </span>
                  ) : null}
                </span>
              </div>
            ) : null}
            {displaySurcharge && recurringAmountSurcharge > 0 ? (
              <div className="w-full grid grid-cols-12">
                <span
                  className="col-span-6 lg:col-span-5 xl:col-span-4 dark:text-slate-400"
                  title="Recurring discount"
                >
                  Convenience fee<sup> *</sup>
                </span>
                <span
                  className={clsx(
                    'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12',
                    'text-right whitespace-nowrap',
                  )}
                >
                  <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                    <Price
                      priceWithTax={recurringAmountSurcharge}
                      currencyCode={currency as CurrencyCode}
                      className={clsx('text-right dark:text-slate-200')}
                    />
                  </span>
                  {autoRenew ? (
                    <span
                      className={clsx(
                        'col-span-4 lg:col-span-5 xl:col-span-3',
                        'text-left text-success-600 dark:text-success-400',
                      )}
                    >
                      /{shorten(interval, intervalCount)}
                    </span>
                  ) : null}
                </span>
              </div>
            ) : null}
            {/*discountPerInterval > 0 ? (
              <div className="w-full grid grid-cols-12">
                <span
                  className="col-span-6 md:col-span-5 xl:col-span-4 dark:text-slate-400"
                  title="Recurring discount"
                >
                  Downpayment discount
                </span>
                <Price
                  priceWithTax={-discountPerInterval}
                  currencyCode={currency as CurrencyCode}
                  className={clsx(
                    'col-start-7 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                  )}
                />
              </div>
            ) : null*/}
            {finalAmount > 0 ? (
              <span className="flex">
                <span className="dark:text-slate-400">First charge</span>&nbsp;
                <span
                  className={clsx({
                    'text-primary-500 dark:text-primary-400 cursor-pointer':
                      !!onEdit && editable.includes('dates'),
                  })}
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    handleEdit(e, 'dates', 'charge-date')
                  }}
                >
                  {userDateFormat(startDate)}
                </span>
              </span>
            ) : null}
            {autoRenew ? (
              <div className="flex">
                <span className="dark:text-slate-400">Paid</span>&nbsp;
                <span
                  className={clsx('', {
                    'text-primary-500 dark:text-primary-400 cursor-pointer':
                      !!onEdit && editable.includes('interval'),
                  })}
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    handleEdit(e, 'interval')
                  }}
                >
                  {intervalCount > 1 ? `Every ${intervalCount} ` : ''}
                  {capitalize(interval)}
                  {intervalCount > 1 ? 's' : 'ly'}
                </span>
              </div>
            ) : null}
          </div>
          <div
            className={clsx('w-full grid grid-cols-12', {
              'cursor-pointer': displayDetails,
              // 'w-full justify-between': proration === 0,
            })}
            onClick={
              autoRenew ||
              recurringAmountSurcharge > 0 ||
              recurringPromotionDiscount > 0
                ? handleToggle
                : undefined
            }
          >
            <div className="col-span-6 lg:col-span-5 xl:col-span-6 pt-0.5 flex items-center gap-0.5 mr-0.5">
              <span className="text-success-400">
                {autoRenew
                  ? 'Subscription payment'
                  : `Payment ${userDateFormat(startDate)}`}
              </span>
              {autoRenew ||
              recurringAmountSurcharge > 0 ||
              recurringPromotionDiscount > 0 ? (
                <ChevronRightIcon
                  aria-hidden="true"
                  className={clsx(
                    'h-3 w-3 text-success-500 dark:text-success-400',
                    {
                      hidden: !displayDetails,
                      '-rotate-90': isOpen,
                    },
                  )}
                />
              ) : null}
            </div>
            <span
              className={clsx(
                'col-start-7 col-span-4 md:col-start-7 lg:col-start-7 lg:col-span-3 xl:col-start-7 xl:col-span-3 grid grid-cols-12',
                'text-right whitespace-nowrap',
                {
                  'border-t border-gray-600': isOpen,
                },
              )}
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                handleEdit(e, 'amounts', 'recurring-discount')
              }}
            >
              <span className="text-right col-span-8 lg:col-span-7 xl:col-span-9">
                <Price
                  priceWithTax={finalAmount}
                  currencyCode={currency as CurrencyCode}
                  className={clsx('text-right', {
                    'dark:text-slate-200': !(
                      !!onEdit && editable.includes('amounts')
                    ),
                    'text-primary-500 dark:text-primary-400 cursor-pointer':
                      !!onEdit && editable.includes('amounts'),
                  })}
                />
              </span>
              {autoRenew ? (
                <span
                  className={clsx(
                    'col-span-4 lg:col-span-5 xl:col-span-3',
                    'text-left text-success-600 dark:text-success-400',
                  )}
                >
                  /{shorten(interval, intervalCount)}
                </span>
              ) : null}
            </span>
          </div>
        </>
      )}
    </div>
  )
}
