// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/agreement/config.ts"
);
import.meta.hot.lastModified = "1734390566000";
}
// REMIX HMR END

export const config = {
  apiPath: '/api/agreement',
}
